import React, {useEffect, useState} from 'react'
import {
  Checkbox,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Space,
  Typography,
} from 'antd'
import {$, textStore} from '../../stores/localization/localization'
import {userStore} from '../../stores/userStore'
import './albumForm.less'
import UploadImage from '../Upload/UploadImage'
import {AlbumState} from '../../const/album'
import {displayError} from '../../utils/displayNotification'
import TrackUpload from '../Upload/TrackUpload'
import {addAlbumPicture, getAlbumById} from '../../fetchers/albumFetchers'
import useAsyncEffect from 'use-async-effect'
import {subscribeToTracksWithAlbumId} from '../../fetchers/trackFetchers'

interface Props {
  albumId: string
  formRef: FormInstance<FormData>
  initialValues?: IInitialValues
  onSubmit: (values: FormData) => void
  coverImage: string | undefined
  setCoverImage: (url: string | undefined) => void
}

interface IInitialValues extends FormData {
  tempTrack?: string
  albumCover?: string
}

export interface FormData {
  name?: string
  releaseYear?: number
  isSingle: boolean
}

//10MB
const PIC_SIZE_LIMIT = 10 * 1024 * 1024

const AlbumForm = ({
  albumId,
  formRef,
  initialValues,
  onSubmit,
  coverImage,
  setCoverImage,
}: Props) => {
  const [nbrTracks, setNbrTracks] = useState<number>(0)
  const [isSingle, setIsSingle] = useState<boolean>(false)
  const [photoUploading, setPhotoUploading] = useState<boolean>(false)
  const [albumState, setAlbumState] = useState<AlbumState | undefined>(AlbumState.temp)

  const {key} = textStore()
  const {user} = userStore()

  useAsyncEffect(
    async isActive => {
      if (!albumId) return

      const albumData = await getAlbumById(albumId)
      if (!isActive()) return

      setCoverImage(albumData?.albumCover ?? undefined)
      setAlbumState(albumData?.albumState ?? undefined)
    },
    [albumId]
  )

  useEffect(() => {
    return subscribeToTracksWithAlbumId(albumId, tracks => {
      setNbrTracks(tracks.length)
    })
  }, [albumId])

  const submit = (values: FormData) => {
    if (nbrTracks === 0) {
      displayError($(key.error_title), $(key.album_submit_error_no_tracks))
      return
    }

    if (nbrTracks > 1 && values.isSingle) {
      displayError($(key.error_title), $(key.album_submit_error_single_more_tracks))
      return
    }

    if (coverImage === undefined) {
      displayError($(key.error_title), $(key.album_submit_error_no_cover))
      return
    }

    formRef.validateFields().then(() => onSubmit(values))
  }

  const handleImageDelete = () => {
    if (albumState === AlbumState.published) {
      displayError($(key.error_title), $(key.picture_error_deletion_impossible))
    } else {
      setCoverImage(undefined)
    }
  }

  const handleImageUpload = async (image: Blob) => {
    if (image.size > PIC_SIZE_LIMIT) {
      displayError($(key.error_title), $(key.setup_step1_file_too_large))
    }

    if (user) {
      const uploadTask = await addAlbumPicture(user.id, albumId, image)
      if (!uploadTask) {
        return
      }

      uploadTask.task.on(
        'state_changed',
        () => {},
        () => {
          displayError($(key.error_title), $(key.picture_upload_error))
          setPhotoUploading(false)
        },
        async () => {
          setPhotoUploading(false)

          const pictureURL = await uploadTask.ref.getDownloadURL()
          setCoverImage(pictureURL)
        }
      )
    }
  }

  return (
    <>
      <Row className='album-form-container'>
        <Col span={24}>
          <Form form={formRef} layout='vertical' initialValues={initialValues} onFinish={submit}>
            <Row gutter={[0, 15]}>
              <Col xs={24} md={19}>
                <Form.Item
                  name='name'
                  label={$(key.albumName)}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}>
                  <Input disabled={albumState === AlbumState.published} />
                </Form.Item>
              </Col>
              <Col xs={24} md={19}>
                <Form.Item
                  name='releaseYear'
                  label={$(key.albumYear)}
                  rules={[
                    {
                      required: true,
                      message: '',
                    },
                  ]}>
                  <InputNumber
                    style={{width: '100%'}}
                    disabled={albumState === AlbumState.published}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name='isSingle' valuePropName='checked'>
                  <Checkbox
                    onChange={e => setIsSingle(e.target.checked)}
                    disabled={
                      nbrTracks > 1 ||
                      (albumState &&
                        [AlbumState.published, AlbumState.unpublished].includes(albumState))
                    }>
                    {$(key.music_album_create_text_is_single)}
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={$(key.album_cover)}>
                  <Typography.Paragraph type='secondary'>
                    {$(key.picture_upload_formats)}
                  </Typography.Paragraph>
                  <Typography.Paragraph type='secondary'>
                    {$(key.picture_upload_size)}
                  </Typography.Paragraph>
                  <Space size={30}>
                    <UploadImage
                      pictureUrl={coverImage}
                      uploading={photoUploading}
                      handleDelete={handleImageDelete}
                      handleImageUpload={handleImageUpload}
                    />
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider />
          <TrackUpload albumId={albumId} single={isSingle} />
        </Col>
      </Row>
    </>
  )
}

export default AlbumForm
