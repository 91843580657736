import React, {useState} from 'react'
import {Button, Divider, Row, Typography} from 'antd'
import {auth} from '../../utils/firebase'
import {userStore} from '../../stores/userStore'
import './userProfile.less'
import Loading from '../../sharedComponents/Loading/Loading'
import HOCGeneralUI from '../../sharedComponents/UI/HOCGeneralUI'
import {$, textStore} from '../../stores/localization/localization'
import {ArrowRightOutlined} from '@ant-design/icons'
import {Link} from 'react-router-dom'
import {LOCAL_STORAGE_RESET_PASSWORD, ROUTE_HOME} from '../../Routes'
import {Helmet} from 'react-helmet'
import {displayError} from '../../utils/displayNotification'
import UserProfileForm from './UserProfileForm'
import {updateUserWithId} from '../../fetchers/usersFetcher'

interface FormData {
  firstName: string
  lastName: string
  email: string
}

const UserProfile = () => {
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false)

  const {user} = userStore()
  const {key} = textStore()

  const clickLogout = async () => {
    await auth.signOut()
  }

  const changePassword = async () => {
    localStorage.setItem(LOCAL_STORAGE_RESET_PASSWORD, 'true')
    await clickLogout()
  }

  const requireLogout = async () => {
    displayError($(key.user_account_should_log_message), $(key.user_account_should_log_desc))
    await clickLogout()
  }

  const submit = async (data: FormData) => {
    if (!user) return

    setSubmitButtonLoading(true)

    const {firstName, lastName, email} = data
    await updateUserWithId(user.id, {firstName, lastName, email})

    if (auth.currentUser) {
      const displayName = `${firstName} ${lastName}`

      if (email !== auth.currentUser.email) {
        try {
          await auth.currentUser.updateEmail(email)
        } catch (e: any) {
          if (e.code === 'auth/requires-recent-login') {
            await requireLogout()
            return
          } else {
            displayError($(key.error_title), e.message)
          }
        }
      }

      if (displayName !== auth.currentUser.displayName) {
        try {
          await auth.currentUser.updateProfile({displayName})
        } catch (e: any) {
          if (e.code === 'auth/requires-recent-login') {
            await requireLogout()
            return
          } else {
            displayError($(key.error_title), e.message)
          }
        }
      }
    }

    setSubmitButtonLoading(false)
  }

  if (!user) return <Loading />

  return (
    <HOCGeneralUI title={$(key.user_account_title)}>
      <div className='user-account-container'>
        <Helmet>
          <title>{$(key.page_title_artist_private_profile)}</title>
        </Helmet>
        <Typography.Paragraph strong>{$(key.user_account_modify_infos)}</Typography.Paragraph>
        <Typography.Paragraph>{$(key.user_account_private_info)}</Typography.Paragraph>
        <UserProfileForm submit={submit} loading={submitButtonLoading} />
        <Divider />
        <Row>
          <Typography.Text strong>{$(key.user_account_other_options)}</Typography.Text>
        </Row>
        <Row>
          <Link to={ROUTE_HOME}>
            <Button type='link' onClick={changePassword} className='link-btn'>
              {$(key.user_account_change_password)} <ArrowRightOutlined />
            </Button>
          </Link>
        </Row>
        <Row>
          <Button type='link' onClick={clickLogout} className='link-btn'>
            {$(key.login_title_logout)}
          </Button>
        </Row>
      </div>
    </HOCGeneralUI>
  )
}

export default UserProfile
