export const ROUTE_HOME = '/'
export const ROUTE_CUSTOMER = '/customer-landing'
export const ROUTE_LOGIN = '/login'
export const ROUTE_LOGIN_UUID = '/login/:token'
export const ROUTE_MANAGE_ARTISTS = '/manage-artists'
export const ROUTE_SETUP = '/setup'
export const ROUTE_ACCOUNT = '/account'
export const ROUTE_COMPENSATION = '/compensation'
export const ROUTE_COMPENSATION_DETAILS = '/compensation/details'
export const ROUTE_ALBUMS = '/albums'
export const ROUTE_ADD_ALBUM = '/create-album'
export const ROUTE_EDIT_ALBUM = '/edit-album/:albumId'
export const ROUTE_NOT_FOUND = '/404'

export const LOCAL_STORAGE_RESET_PASSWORD = 'reset_password'
