export function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement('canvas')
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const finalWidth = crop.width * scaleX
  const finalHeight = crop.height * scaleY

  canvas.width = finalWidth
  canvas.height = finalHeight
  const ctx = canvas.getContext('2d')

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    finalWidth,
    finalHeight,
    0,
    0,
    finalWidth,
    finalHeight
  )

  return new Promise(resolve => {
    canvas.toBlob(file => {
      file.filterName = fileName
      resolve(file)
    }, 'image/jpeg')
  })
}
