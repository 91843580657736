import React from 'react'
import {Col, Row} from 'antd'
import AddArtistModal from './components/AddArtistModal'
import ArtistTable from './components/ArtistTable'
import {$, textStore} from '../../stores/localization/localization'
import './manageArtists.less'
import HOCGeneralUI from '../../sharedComponents/UI/HOCGeneralUI'
import {Helmet} from 'react-helmet'

const ManageArtists = () => {
  const {key} = textStore()

  return (
    <>
      <Helmet>
        <title>{$(key.page_title_admin_manage_artists)}</title>
      </Helmet>
      <HOCGeneralUI title={$(key.artist_manager_title_main)}>
        <Row className='manage-artist-container' gutter={[0, 16]}>
          <Col span={24}>
            <AddArtistModal />
          </Col>
          <Col span={24}>
            <ArtistTable />
          </Col>
        </Row>
      </HOCGeneralUI>
    </>
  )
}

export default ManageArtists
