import React, {useEffect, useState} from 'react'
import {Button, Col, Divider, Row, Space, Typography} from 'antd'
import {$, textStore} from '../../stores/localization/localization'
import money from '../../assets/moneyBag.svg'
import dayjs from 'dayjs'
import {Link, useHistory} from 'react-router-dom'
import yellowArrowRight from '../../assets/yellowArrowRight.svg'
import {Sale} from '../../models/toRefactor/Sale'
import HOCGeneralUI from '../../sharedComponents/UI/HOCGeneralUI'
import {ROUTE_COMPENSATION} from '../../Routes'
import {fetchTrackWithId} from '../../fetchers/trackFetchers'
import Loading from '../../sharedComponents/Loading/Loading'
import './compensationDetails.less'
import {getPaymentConfig} from '../../fetchers/configFetchers'
import {userStore} from '../../stores/userStore'
import {requestCompensation} from '../../fetchers/backendFetcher'
import useAsyncEffect from 'use-async-effect'
import {PaymentConfig} from '../../models/Config'
import groupBy from 'lodash/groupBy'
import {displayError, displaySuccess} from '../../utils/displayNotification'

interface Props {
  location: any
}

interface trackMonthlySale {
  trackName: string
  sales: number
}

const CompensationDetails = ({location}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [emailLoading, setEmailLoading] = useState<boolean>(false)
  const [monthSales, setMonthSales] = useState<number>()
  const [trackSales, setTrackSales] = useState<trackMonthlySale[]>([])
  const [paymentConfig, setPaymentConfig] = useState<PaymentConfig>()
  const [date, setDate] = useState<Date>()

  const history = useHistory()
  const {key} = textStore()
  const {user} = userStore()

  useAsyncEffect(async isActive => {
    const paymentData = await getPaymentConfig()
    if (!isActive()) return
    setPaymentConfig(paymentData)
  })

  useEffect(() => {
    setIsLoading(true)
    const selectedMonth: Sale[] | undefined = location.state.selectedMonth
    const date: string | undefined = location.state.date

    if (selectedMonth === undefined || date === undefined) {
      history.push(ROUTE_COMPENSATION)
      return
    }

    const tempTrackSales = groupBy(selectedMonth, 'trackId')

    Promise.all(
      Object.keys(tempTrackSales).map(item => {
        return fetchTrackWithId(item).then(track => {
          return {
            trackName: track?.name ?? '',
            sales: tempTrackSales[item].length,
          } as trackMonthlySale
        })
      })
    ).then(trackSales => {
      setTrackSales(trackSales)
      setIsLoading(false)
    })

    setDate(new Date(date))
    setMonthSales(selectedMonth.length)
  }, [history, location.state.date, location.state.selectedMonth])

  const handleRemunerationEmail = async () => {
    setEmailLoading(true)
    if (date && monthSales && user && paymentConfig) {
      try {
        await requestCompensation(
          user.displayName,
          user.email,
          date,
          monthSales,
          monthSales * paymentConfig.trackPriceUSD * paymentConfig.artistCut
        )
        displaySuccess($(key.success_title), $(key.compensation_email_sent))
      } catch (e: any) {
        setEmailLoading(false)
        displayError($(key.error_title), e.message)
      }
    } else {
      displayError($(key.error_title), $(key.firebase_signon_genericError))
    }
    setEmailLoading(false)
  }

  const renderSelectedMonth = (
    <Typography.Paragraph className='details-text'>
      {monthSales &&
        date &&
        paymentConfig &&
        $(
          key.compensation_details_text,
          dayjs(date).format('MMMM'),
          date.getFullYear(),
          monthSales,
          monthSales * paymentConfig.trackPriceUSD * paymentConfig.artistCut
        )}
    </Typography.Paragraph>
  )

  const RenderTrackList = () => {
    return (
      <>
        {trackSales &&
          trackSales.map((item, index) => {
            return (
              <Row key={index} className='full-row'>
                <Col span={4}>
                  <Typography.Text strong>{item.trackName}</Typography.Text>
                </Col>
                <Col span={20}>{$(key.compensation_details_sales_total, item.sales)}</Col>
              </Row>
            )
          })}
      </>
    )
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <HOCGeneralUI title={$(key.compensation_details_title)}>
      <Row className='compensation-details-container'>
        <Col span={24}>
          <Link to={ROUTE_COMPENSATION}>
            <Typography.Text strong>
              <Space>
                <img src={yellowArrowRight} alt={$(key.close)} className='details-back-icon' />
                {dayjs(date).format('MMMM YYYY')}
              </Space>
            </Typography.Text>
          </Link>
          {renderSelectedMonth}
          <Button type='primary' onClick={handleRemunerationEmail} loading={emailLoading}>
            <Space>
              <img src={money} alt={$(key.compensation_details_button)} />
              {$(key.compensation_details_button)}
            </Space>
          </Button>
          <Divider />
          <Typography.Text strong>{$(key.compensation_details_summary)}</Typography.Text>
          <Row gutter={[0, 10]} className='summary-row'>
            {trackSales ? <RenderTrackList /> : <Loading />}
          </Row>
        </Col>
      </Row>
    </HOCGeneralUI>
  )
}

export default CompensationDetails
