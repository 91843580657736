import {remoteConfig} from '../utils/firebase'
import {Currency, PaymentConfig} from '../models/Config'

export const getPaymentConfig = async () => {
  await remoteConfig.fetchAndActivate()
  const trackPriceUSD = remoteConfig.getNumber('track_price_usd')
  const artistCut = remoteConfig.getNumber('artist_cut')
  const supportedCurrencies = JSON.parse(remoteConfig.getString('supported_currencies'))

  const currencies = supportedCurrencies.map(
    (curr: any) => new Currency(curr.fromUSD, curr.type, curr.name)
  )

  return new PaymentConfig(trackPriceUSD, artistCut, currencies)
}
