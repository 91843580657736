import {auth, db} from '../utils/firebase'
import {Sale} from '../models/toRefactor/Sale'

const salesRef = db.collection('sales')

export const fetchCompensation: () => Promise<Sale[] | undefined> = async () => {
  const currentUser = auth.currentUser

  if (!currentUser?.uid) return undefined

  const salesQuery = await salesRef
    .where('artist.id', '==', currentUser.uid)
    .where('status', '==', 'OK')
    .get()

  if (salesQuery.empty) return undefined

  return salesQuery.docs.map(item => {
    return {...item.data(), id: item.id} as Sale
  })
}
