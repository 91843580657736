import React from 'react'
import {Card, Col, Row, Typography} from 'antd'
import './HOCGeneralUI.less'

interface Props {
  title: string
  children: any
}

const HOCGeneralUI = ({title, children}: Props) => {
  return (
    <Row className='main-UI-container' justify='center'>
      <Col xs={24} md={12}>
        <Row>
          <Col>
            <Typography.Title className='title-container' level={3}>
              {title}
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card>{children}</Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default HOCGeneralUI
