import React, {useState} from 'react'
import {Button, Col, Form, Row} from 'antd'
import ArtistInfoForm from '../sharedComponents/Forms/ArtistInfoForm'
import HOCGeneralUI from '../sharedComponents/UI/HOCGeneralUI'
import {$, textStore} from '../stores/localization/localization'
import {userStore} from '../stores/userStore'
import {Helmet} from 'react-helmet'
import {displayError, displaySuccess} from '../utils/displayNotification'
import {updateUserWithId} from '../fetchers/usersFetcher'

const Home = () => {
  const [uploading, setUploading] = useState<boolean>(false)
  const {user} = userStore()
  const {key} = textStore()
  const formRef = Form.useForm()[0]

  const onSubmit = async () => {
    if (!user) return

    setUploading(true)

    try {
      const {artistName, artistBio, artistPhoto = null} = formRef.getFieldsValue()
      await updateUserWithId(user.id, {artistName, artistBio, artistPhoto})
      displaySuccess($(key.success_title), $(key.home_updated_success))
    } catch (e: any) {
      displayError($(key.error_title), e.message)
    } finally {
      setUploading(false)
    }
  }

  return (
    <>
      <Helmet>
        <title>{$(key.page_title_artist_public_profile)}</title>
      </Helmet>
      <HOCGeneralUI title={$(key.home_title_main)}>
        <Row className='home-container' justify='end'>
          <Col span={24}>
            <ArtistInfoForm formRef={formRef} />
          </Col>
          <Col>
            <Button type='primary' loading={uploading} onClick={onSubmit}>
              {$(key.save)}
            </Button>
          </Col>
        </Row>
      </HOCGeneralUI>
    </>
  )
}

export default Home
