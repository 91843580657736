import React from 'react'
import RenderMenuItemLink from './RenderMenuItemLink'
import {$, textStore} from '../../../stores/localization/localization'
import {userStore} from '../../../stores/userStore'
import {ROUTE_ACCOUNT, ROUTE_ALBUMS, ROUTE_COMPENSATION, ROUTE_HOME} from '../../../Routes'

const ArtistMenu = () => {
  const {user} = userStore()
  const {key} = textStore()

  return (
    <>
      <RenderMenuItemLink linkURL={ROUTE_HOME} title={$(key.home_title_main)} />
      <RenderMenuItemLink linkURL={ROUTE_ALBUMS} title={$(key.music_album_title_main)} />
      <RenderMenuItemLink linkURL={ROUTE_COMPENSATION} title={$(key.compensation_page_title)} />
      {user && (
        <RenderMenuItemLink
          linkURL={ROUTE_ACCOUNT}
          title={`${user?.firstName} ${user?.lastName}`}
        />
      )}
    </>
  )
}

export default ArtistMenu
