import React from 'react'
import {Button} from 'antd'
import {$, textStore} from '../../../stores/localization/localization'
import {auth} from '../../../utils/firebase'

const CustomerMenu = () => {
  const {key} = textStore()

  const onLogout = async () => {
    await auth.signOut()
  }

  return (
    <Button type='link' onClick={onLogout}>
      <strong>{$(key.login_title_logout)}</strong>
    </Button>
  )
}

export default CustomerMenu
