function computeAudioFileLength(file: File) {
  return new Promise(resolve => {
    const objectURL = URL.createObjectURL(file)
    const mySound = new Audio(objectURL)
    mySound.addEventListener(
      'canplaythrough',
      () => {
        URL.revokeObjectURL(objectURL)
        resolve({
          file,
          duration: mySound.duration,
        })
      },
      false
    )
  })
}

export default computeAudioFileLength
