import React, {useState} from 'react'
import {Alert, Button, Divider, Form, Input, Modal, Space, Typography} from 'antd'
import {EditOutlined} from '@ant-design/icons'
import {$, textStore} from '../../../stores/localization/localization'
import {displayError, displaySuccess} from '../../../utils/displayNotification'
import {createArtistAccount} from '../../../fetchers/backendFetcher'

interface FormProps {
  artistName: string
  email: string
  firstName: string
  lastName: string
}

export const AddArtistModal = () => {
  const [creatingArtist, setCreatingArtist] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const artistFormRef = Form.useForm<FormProps>()[0]
  const {key} = textStore()

  const finalize = async (items: FormProps) => {
    setCreatingArtist(true)

    try {
      const {artistName, email, firstName, lastName} = items
      await createArtistAccount(artistName, email, firstName, lastName)

      setModalOpen(false)
      artistFormRef.resetFields()
      displaySuccess($(key.success_title), $(key.artist_manager_text_created_success, artistName))
    } catch (e: any) {
      if (e.message === 'Request failed with status code 403') {
        displayError($(key.error_title), $(key.artist_manager_text_create_duplicate))
      } else {
        displayError($(key.error_title), $(key.artist_manager_text_create_failed))
      }
    } finally {
      setCreatingArtist(false)
    }
  }

  return (
    <>
      <Modal
        visible={modalOpen}
        closable={false}
        title={$(key.artist_manager_add_artist)}
        cancelText={$(key.cancel)}
        okText={$(key.create)}
        confirmLoading={creatingArtist}
        onOk={artistFormRef.submit}
        onCancel={() => {
          setModalOpen(false)
          artistFormRef.resetFields()
        }}>
        <Typography.Title level={5}>{$(key.artist_manager_public_info)}</Typography.Title>
        <Space direction='vertical' size={20} style={{width: '100%'}}>
          <Form form={artistFormRef} onFinish={finalize} layout='vertical'>
            <Form.Item
              name='artistName'
              label={$(key.display_name)}
              rules={[
                {
                  required: true,
                  message: $(key.artist_manager_text_artist_name_missing),
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name='email'
              label={$(key.email)}
              rules={[
                {
                  required: true,
                  message: $(key.artist_manager_text_email_missing),
                },
                {
                  type: 'email',
                  message: $(key.artist_manager_text_email_malformed),
                },
              ]}>
              <Input />
            </Form.Item>
            <Divider />
            <Typography.Title level={5}>{$(key.artist_manager_private_info)}</Typography.Title>
            <Form.Item
              name='firstName'
              label={$(key.first_name)}
              rules={[
                {
                  required: true,
                  message: $(key.missing_name),
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name='lastName'
              label={$(key.last_name)}
              rules={[
                {
                  required: true,
                  message: $(key.missing_name),
                },
              ]}>
              <Input />
            </Form.Item>
          </Form>
          <Alert message={$(key.artist_manager_timing_disclaimer)} type='warning' showIcon />
        </Space>
      </Modal>
      <Button type='primary' onClick={() => setModalOpen(true)}>
        <Space>
          <EditOutlined />
          {$(key.artist_manager_add_artist)}
        </Space>
      </Button>
    </>
  )
}

export default AddArtistModal
