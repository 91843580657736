import React from 'react'
import {Button, Result} from 'antd'
import './customerLanding.less'
import {$, textStore} from '../../stores/localization/localization'
import {Helmet} from 'react-helmet'

const CustomerLanding = () => {
  const {key} = textStore()

  return (
    <>
      <Helmet>
        <title>{$(key.page_title_home)}</title>
      </Helmet>
      <Result
        className='customer-landing-container'
        status={403}
        title={$(key.customer_landing_title)}
        subTitle={$(key.customer_landing_text)}
        extra={<Button type='primary'>{$(key.customer_landing_button)}</Button>}
      />
    </>
  )
}

export default CustomerLanding
