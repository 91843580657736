import React from 'react'
import {Link} from 'react-router-dom'
import {Col} from 'antd'

interface Props {
  linkURL: string
  title: string
}

const RenderMenuItemLink = ({linkURL, title}: Props) => {
  return (
    <Col className='user-link'>
      <Link to={linkURL}>
        <strong>{title}</strong>
      </Link>
    </Col>
  )
}

export default RenderMenuItemLink
