import React, {useState} from 'react'
import {Col, Typography} from 'antd'
import {$, textStore} from '../../stores/localization/localization'
import HOCGeneralUI from '../../sharedComponents/UI/HOCGeneralUI'
import './compensation.less'
import {Helmet} from 'react-helmet'
import {PaymentConfig} from '../../models/Config'
import CompensationList from './CompensationList'
import useAsyncEffect from 'use-async-effect'
import {getPaymentConfig} from '../../fetchers/configFetchers'

const Compensation = () => {
  const [paymentConfig, setPaymentConfig] = useState<PaymentConfig>()
  const [trackPriceCDF, setTrackPriceCDF] = useState<number>(1000)

  const {key} = textStore()

  useAsyncEffect(async isActive => {
    const paymentData = await getPaymentConfig()
    if (!isActive()) return

    setPaymentConfig(paymentData)

    const cdfData = paymentData.supportedCurrencies.find(item => item.name === 'CDF')
    if (paymentData.trackPriceUSD && cdfData) {
      const trackPrice = paymentData.trackPriceUSD * cdfData.fromUSD
      setTrackPriceCDF(trackPrice)
    }
  }, [])

  return (
    <HOCGeneralUI title={$(key.compensation_page_title)}>
      <>
        <Helmet>
          <title>{$(key.page_title_artist_compensation)}</title>
        </Helmet>
        <Col span={24}>
          <Typography.Paragraph>{$(key.compensation_p_1)}</Typography.Paragraph>
          <Typography.Paragraph>
            {$(key.compensation_p_2, paymentConfig?.trackPriceUSD ?? 0, trackPriceCDF, 'CDF')}
          </Typography.Paragraph>
        </Col>
        <CompensationList paymentConfig={paymentConfig} />
      </>
    </HOCGeneralUI>
  )
}

export default Compensation
