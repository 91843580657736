import React from 'react'
import {useHistory} from 'react-router-dom'
import {Button, Result} from 'antd'
import {$, textStore} from '../stores/localization/localization'
import {ROUTE_HOME} from '../Routes'
import {Helmet} from 'react-helmet'

const NotFound = () => {
  const {key} = textStore()
  const history = useHistory()

  return (
    <>
      <Helmet>
        <title>{$(key.page_title_404)}</title>
      </Helmet>
      <Result
        status='404'
        title='404'
        subTitle={$(key.notFound_text)}
        extra={
          <Button type='primary' onClick={() => history.push(ROUTE_HOME)}>
            {$(key.notFound_button)}
          </Button>
        }
      />
    </>
  )
}

export default NotFound
