import React, {useEffect, useState} from 'react'
import {Col, Form, FormInstance, Input, Row, Space, Typography} from 'antd'
import {$, textStore} from '../../stores/localization/localization'
import {userStore} from '../../stores/userStore'
import UploadImage from '../Upload/UploadImage'
import './artistInfoForm.less'
import {displayError} from '../../utils/displayNotification'
import {addUserProfilePicture} from '../../fetchers/usersFetcher'

interface Props {
  formRef: FormInstance<FormData>
}

export interface FormData {
  artistName?: string
  artistBio?: string
  artistPhoto?: string
}

//10MB
const PIC_SIZE_LIMIT = 10 * 1024 * 1024

const ArtistInfoForm = ({formRef}: Props) => {
  const [photoURL, setPhotoURL] = useState<string | undefined>(undefined)
  const [photoUploading, setPhotoUploading] = useState<boolean>(false)
  const {key} = textStore()
  const {user} = userStore()

  useEffect(() => {
    if (!user) return

    setPhotoURL(user.artistPhoto)
  }, [user])

  const handleImageUpload = async (image: Blob) => {
    if (image.size > PIC_SIZE_LIMIT) {
      displayError($(key.error_title), $(key.setup_step1_file_too_large))
    }

    if (user) {
      const uploadTask = await addUserProfilePicture(user.id, image)

      if (!uploadTask) {
        return
      }

      uploadTask.task.on(
        'state_changed',
        () => {},
        () => {
          displayError($(key.error_title), $(key.picture_upload_error))
          setPhotoUploading(false)
        },
        async () => {
          setPhotoUploading(false)

          const pictureURL = await uploadTask.ref.getDownloadURL()
          setPhotoURL(pictureURL)
          formRef.setFieldsValue({artistPhoto: pictureURL})
        }
      )
    }
  }

  const handleImageDelete = () => {
    setPhotoURL(undefined)
    formRef.setFieldsValue({artistPhoto: undefined})
  }

  return (
    <Form
      form={formRef}
      layout='vertical'
      initialValues={user ?? undefined}
      className='artist-form-container'>
      <Row gutter={[0, 15]}>
        <Col xs={22} md={24}>
          <Form.Item
            name='artistName'
            label={$(key.artist_name)}
            rules={[
              {
                required: true,
                message: $(key.setup_step0_text_artist_name_missing),
              },
            ]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={22} md={24}>
          <Form.Item name='artistBio' label={$(key.biography)}>
            <Input.TextArea maxLength={300} autoSize={{minRows: 2, maxRows: 8}} showCount />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row>
            <Col>
              <Form.Item label={$(key.profile_picture)}>
                <Typography.Paragraph type='secondary' className='paragraph-image-info'>
                  {$(key.picture_upload_formats)}
                </Typography.Paragraph>
                <Typography.Paragraph type='secondary' className='paragraph-image-info'>
                  {$(key.picture_upload_size)}
                </Typography.Paragraph>
                <Space size={30}>
                  <Form.Item name='artistPhoto'>
                    <UploadImage
                      pictureUrl={photoURL}
                      uploading={photoUploading}
                      handleDelete={handleImageDelete}
                      handleImageUpload={handleImageUpload}
                    />
                  </Form.Item>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default ArtistInfoForm
