import {albumPictureStorage, db} from '../utils/firebase'
import {ALBUMS_COLLECTION} from '../const/db'
import {AlbumState} from '../const/album'
import {FirestoreAlbum} from '../models/toRefactor/Album'

const albumsColRef = db.collection(ALBUMS_COLLECTION)

export const getNewAlbumId = () => {
  return db.collection('albums').doc().id
}

export const updateAlbum = async (id: string, payload: any) => {
  await albumsColRef.doc(id).update(payload)
}

export const createEmptyAlbum = async (id: string) => {
  await albumsColRef.doc(id).set({})
}

export const createAlbum = async (id: string, payload: any) => {
  await albumsColRef.doc(id).set(payload, {merge: true})
}

export const changeAlbumState = async (id: string, state: AlbumState) => {
  await albumsColRef.doc(id).update({albumState: state})
}

export const addAlbumPicture = (uid: string, aId: string, file: Blob) => {
  const storageRef = albumPictureStorage.ref(`${uid}/${aId}`)
  return storageRef.put(file)
}

export const subscribeToArtistAlbum = async (
  id: string,
  callback: (albums: FirestoreAlbum[]) => void
) => {
  await albumsColRef.where('artist.id', '==', id).onSnapshot(snapshot => {
    if (snapshot.empty) {
      callback([])
    }

    const albums = snapshot.docs.map(item => {
      return {
        id: item.id,
        ...item.data(),
      } as FirestoreAlbum
    })

    callback(albums)
  })
}

export const getAlbumById = async (id: string) => {
  const result = await albumsColRef.doc(id).get()

  if (!result.exists) return null

  return {
    id: result.id,
    ...result.data(),
  } as FirestoreAlbum
}
