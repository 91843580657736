import React, {useEffect, useState} from 'react'
import {Route, Switch} from 'react-router-dom'
import {Layout} from 'antd'
import Home from './pages/Home'
import Login from './pages/Login/Login'
import ManageArtists from './pages/ArtistManager/ManageArtists'
import CustomerLanding from './pages/CustomerLanding/CustomerLanding'
import Setup from './pages/Setup/Setup'
import UserProfile from './pages/UserProfile/UserProfile'
import NotFound from './pages/NotFound'
import NavBar from './sharedComponents/NavBar/NavBar'
import {auth} from './utils/firebase'
import {userStore} from './stores/userStore'
import PrivateRoute from './utils/routing/PrivateRoute'
import './app.less'
import Compensation from './pages/Compensation/Compensation'
import CompensationDetails from './pages/CompensationDetails/CompensationDetails'
import {UserType} from './models/toRefactor/User'
import Albums from './pages/Albums/Albums'
import AlbumCreate from './pages/AlbumCreate'
import AlbumEdit from './pages/AlbumEdit'
import Loading from './sharedComponents/Loading/Loading'
import LoginUUID from './pages/LoginUUID/LoginUUID'
import {
  ROUTE_ACCOUNT,
  ROUTE_ADD_ALBUM,
  ROUTE_ALBUMS,
  ROUTE_COMPENSATION,
  ROUTE_COMPENSATION_DETAILS,
  ROUTE_CUSTOMER,
  ROUTE_EDIT_ALBUM,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_LOGIN_UUID,
  ROUTE_MANAGE_ARTISTS,
  ROUTE_SETUP,
} from './Routes'

function App() {
  const [loading, setLoading] = useState<boolean>(true)
  const {login, logout} = userStore()

  useEffect(() => {
    auth.onAuthStateChanged(async userAuth => {
      if (userAuth) {
        login(userAuth)
      } else {
        logout()
      }

      setLoading(false)
    })
  }, [logout, login])

  if (loading) {
    return <Loading />
  }

  return (
    <Layout>
      <Layout.Header>
        <NavBar />
      </Layout.Header>
      <Layout.Content>
        <Switch>
          <Route exact path={ROUTE_LOGIN} component={Login} />
          <Route exact path={ROUTE_LOGIN_UUID} component={LoginUUID} />
          <PrivateRoute
            exact
            path={ROUTE_HOME}
            component={Home}
            userTypesAllowed={[UserType.Artist]}
          />
          <PrivateRoute
            exact
            path={ROUTE_ALBUMS}
            component={Albums}
            userTypesAllowed={[UserType.Artist]}
          />
          <PrivateRoute
            exact
            path={ROUTE_ADD_ALBUM}
            component={AlbumCreate}
            userTypesAllowed={[UserType.Artist]}
          />
          <PrivateRoute
            exact
            path={ROUTE_EDIT_ALBUM}
            component={AlbumEdit}
            userTypesAllowed={[UserType.Artist]}
          />
          <PrivateRoute
            exact
            path={ROUTE_SETUP}
            component={Setup}
            userTypesAllowed={[UserType.Artist]}
          />
          <PrivateRoute
            exact
            path={ROUTE_ACCOUNT}
            component={UserProfile}
            userTypesAllowed={[UserType.Artist]}
          />
          <PrivateRoute
            exact
            path={ROUTE_MANAGE_ARTISTS}
            component={ManageArtists}
            userTypesAllowed={[UserType.Admin]}
          />
          <PrivateRoute
            exact
            component={Compensation}
            userTypesAllowed={[UserType.Artist]}
            path={ROUTE_COMPENSATION}
          />
          <PrivateRoute
            exact
            component={CompensationDetails}
            userTypesAllowed={[UserType.Artist]}
            path={ROUTE_COMPENSATION_DETAILS}
          />
          <PrivateRoute
            exact
            path={ROUTE_CUSTOMER}
            component={CustomerLanding}
            userTypesAllowed={[UserType.Customer]}
          />
          <Route component={NotFound} />
        </Switch>
      </Layout.Content>
    </Layout>
  )
}

export default App
