import React, {useState} from 'react'
import {Button, Col, Form, Input, Row, Space, Typography} from 'antd'
import {auth} from '../../../utils/firebase'
import {$, textStore} from '../../../stores/localization/localization'
import {ArrowRightOutlined} from '@ant-design/icons'
import {Helmet} from 'react-helmet'
import {SETUP_STEP} from '../../../models/toRefactor/User'
import {displayError} from '../../../utils/displayNotification'

interface FormData {
  password: string
}

interface Props {
  setStep: (step: SETUP_STEP) => void
}

const Step0 = ({setStep}: Props) => {
  const [submitting, setSubmitting] = useState(false)
  const {key} = textStore()

  const submit = async (data: FormData) => {
    setSubmitting(true)

    try {
      await auth.currentUser?.updatePassword(data.password)
      setStep(SETUP_STEP.editProfile)
    } catch (e: any) {
      if (e.code === 'auth/weak-password') {
        displayError($(key.setup_step0_title_password_weak), e.message)
      } else if (e.code === 'auth/requires-recent-login') {
        displayError($(key.setup_step0_title_login_expired), $(key.setup_step0_text_login_expired))
        await auth.signOut()
      } else {
        displayError($(key.error_title), $(key.setup_text_unknown_error))
      }
    }

    setSubmitting(false)
  }

  return (
    <Row justify='center' gutter={[0, 40]}>
      <Helmet>
        <title>{$(key.page_title_artist_setup_0)}</title>
      </Helmet>
      <Col span={24}>
        <Typography.Title level={4}>{$(key.setup_step0_title)}</Typography.Title>
        <Typography.Text>{$(key.setup_step0_text)}</Typography.Text>
      </Col>
      <Col span={24}>
        <Form layout='vertical' onFinish={submit} requiredMark={false}>
          <Row justify='end' gutter={[0, 40]}>
            <Col span={24}>
              <Form.Item
                name='password'
                label={$(key.setup_step0_password)}
                rules={[
                  {
                    required: true,
                    message: $(key.setup_step0_text_password_missing),
                  },
                ]}>
                <Input.Password />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type='primary' loading={submitting} htmlType='submit'>
                  <Space>
                    {$(key.setup_next_button)} <ArrowRightOutlined />
                  </Space>
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default Step0
