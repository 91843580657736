import {notification} from 'antd'

export const displayError = (code: string, message: string) => {
  notification.error({
    message: code,
    description: message,
  })
}

export const displaySuccess = (code: string, message: string) => {
  notification.success({
    message: code,
    description: message,
  })
}
