import React from 'react'
import {Link} from 'react-router-dom'
import {Table, Tooltip} from 'antd'
import {FirestoreAlbum} from '../../../models/toRefactor/Album'
import {ArrowRightOutlined, EyeInvisibleTwoTone, EyeTwoTone} from '@ant-design/icons'
import {$, textStore} from '../../../stores/localization/localization'
import {AlbumState, albumState} from '../../../const/album'
import {ROUTE_EDIT_ALBUM} from '../../../Routes'

interface Props {
  values: FirestoreAlbum[] | undefined
}

const RenderTable = ({values}: Props) => {
  const {key} = textStore()

  const columns = [
    {
      title: $(key.albums_list_title_status),
      dataIndex: 'albumState',
      render: (state: albumState) =>
        state === AlbumState.published ? (
          <Tooltip title={$(key.albums_list_album_published)}>
            <EyeTwoTone twoToneColor='#7eb77f' />
          </Tooltip>
        ) : (
          <Tooltip
            title={
              state === AlbumState.unpublished
                ? $(key.albums_list_album_unpublished)
                : $(key.albums_list_album_not_published)
            }>
            <EyeInvisibleTwoTone
              twoToneColor={state === AlbumState.unpublished ? '#7eb77f' : 'red'}
            />
          </Tooltip>
        ),
      key: 'albumState',
    },
    {
      title: $(key.albums_list_title_name),
      dataIndex: 'name',
      render: (name: string) => <strong key={name}>{name}</strong>,
      key: 'name',
    },
    {
      title: $(key.albums_list_title_release_year),
      dataIndex: 'releaseYear',
      key: 'releaseYear',
    },
    {
      dataIndex: 'id',
      width: 100,
      render: (id: string) => (
        <Link to={ROUTE_EDIT_ALBUM.replace(':albumId', id)} key={id}>
          Details <ArrowRightOutlined />
        </Link>
      ),
      key: 'id',
    },
  ]

  return <Table columns={columns} dataSource={values} rowKey='id' pagination={false} />
}

export default RenderTable
