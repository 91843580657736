import React, {useState} from 'react'
import {Button, Col, Form, Input, Row} from 'antd'
import {$, textStore} from '../../../stores/localization/localization'
import {userStore} from '../../../stores/userStore'
import ArtistInfoForm from '../../../sharedComponents/Forms/ArtistInfoForm'
import './step1.less'
import {Helmet} from 'react-helmet'
import {updateUserWithId} from '../../../fetchers/usersFetcher'
import {SETUP_STEP} from '../../../models/toRefactor/User'

interface Props {
  setStep: (step: number) => void
}

interface IUserFormData {
  firstName: string
  lastName: string
}

interface IArtistFormData {
  artistName: string
  artistBio: string
  artistPhoto: string
}

const Step1 = ({setStep}: Props) => {
  const [updating, setUpdating] = useState(false)

  const {key} = textStore()
  const {user} = userStore()

  const userFormRef = Form.useForm<IUserFormData>()
  const artistFormRef = Form.useForm<IArtistFormData>()

  const submit = async () => {
    if (!user) return

    await userFormRef[0].validateFields()
    await artistFormRef[0].validateFields()

    const userData: IUserFormData = userFormRef[0].getFieldsValue()
    const artistData: IArtistFormData = artistFormRef[0].getFieldsValue()

    if (userData.firstName && userData.lastName && artistData.artistName) {
      setUpdating(true)
      await updateUserWithId(user.id, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        artistName: artistData.artistName,
        artistBio: artistData.artistBio ?? null,
        artistPhoto: artistData.artistPhoto ?? null,
      })

      setStep(SETUP_STEP.finish)
      setUpdating(false)
    }
  }

  return (
    <Row className='setup-step1-container' gutter={[0, 20]} justify='end'>
      <Helmet>
        <title>{$(key.page_title_artist_setup_1)}</title>
      </Helmet>
      <Col span={24}>
        <Form form={userFormRef[0]} layout='vertical' initialValues={user ?? undefined}>
          <Row gutter={[0, 20]}>
            <Col xs={22} md={11}>
              <Form.Item
                name='firstName'
                label={$(key.first_name)}
                rules={[
                  {
                    required: true,
                    message: $(key.setup_step0_text_first_name_missing),
                  },
                ]}>
                <Input name='firstName' />
              </Form.Item>
            </Col>
            <Col xs={{span: 22, offset: 0}} md={{span: 11, offset: 2}}>
              <Form.Item
                name='lastName'
                label={$(key.last_name)}
                rules={[
                  {
                    required: true,
                    message: $(key.setup_step0_text_last_name_missing),
                  },
                ]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={24}>
        <ArtistInfoForm formRef={artistFormRef[0]} />
      </Col>
      <Col>
        <Button type='primary' loading={updating} onClick={submit}>
          {$(key.setup_step1_next)}
        </Button>
      </Col>
    </Row>
  )
}

export default Step1
