import React from 'react'
import {Button, Col, Result, Row, Space} from 'antd'
import {useHistory} from 'react-router-dom'
import {$, textStore} from '../../../stores/localization/localization'
import {ROUTE_ADD_ALBUM, ROUTE_HOME} from '../../../Routes'
import {Helmet} from 'react-helmet'

const Step2 = () => {
  const {key} = textStore()
  const history = useHistory()

  return (
    <Row>
      <Helmet>
        <title>{$(key.page_title_artist_setup_2)}</title>
      </Helmet>
      <Col span={24}>
        <Result
          status='success'
          title={$(key.setup_step2_title)}
          subTitle={$(key.setup_step2_subtitle)}
        />
      </Col>
      <Col span={24}>
        <Row justify='center'>
          <Space>
            <Button type='primary' onClick={() => history.push(ROUTE_ADD_ALBUM)}>
              {$(key.setup_step2_button_add_artist)}
            </Button>
            <Button type='primary' ghost onClick={() => history.push(ROUTE_HOME)}>
              {$(key.setup_step2_button_home)}
            </Button>
          </Space>
        </Row>
      </Col>
    </Row>
  )
}

export default Step2
