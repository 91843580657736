import {db, trackStorage} from '../utils/firebase'
import {FirestoreTrack} from '../models/toRefactor/Album'
import omit from 'lodash/omit'

const tracksColRef = db.collection('tracks')

export const createTrack = async (track: FirestoreTrack) => {
  const result = await tracksColRef.add(track)
  return {
    ...track,
    id: result.id,
  } as FirestoreTrack
}

export const addTrackToStorage = (uid: string, aId: string, tId: string, file: Blob) => {
  const storageRef = trackStorage.ref(`${uid}/${aId}/${tId}`)
  return storageRef.put(file)
}

export const fetchTrackWithId = async (tId: string) => {
  const result = await tracksColRef.doc(tId).get()

  if (!result.exists) {
    return undefined
  }

  return {
    id: result.id,
    ...result.data(),
  } as FirestoreTrack
}

export const updateTrackWithId = async (tid: string, payload: any) => {
  await tracksColRef.doc(tid).update(payload)
}

export const updateTracksWhenCreatingAlbum = async (albumId: string, album: any) => {
  const tracks = await tracksColRef.where('album.id', '==', albumId).get()
  if (!tracks.empty) {
    tracks.docs.forEach(track => {
      track.ref.update({
        album: {
          id: albumId,
          ...omit(album, ['artist', 'id']),
        },
      })
    })
  }
}

export const deleteTrackWithId = async (track: FirestoreTrack) => {
  await tracksColRef.doc(track.id).delete()
  track.storageLink && (await trackStorage.ref(track.storageLink).delete())
}

export const subscribeToTracksWithAlbumId = (
  id: string,
  callback: (result: FirestoreTrack[]) => void
) => {
  return tracksColRef.where('album.id', '==', id).onSnapshot(snapshot => {
    if (snapshot.empty) {
      callback([])
      return
    }

    const tracks = snapshot.docs.map<FirestoreTrack>(track => {
      return {
        id: track.id,
        ...track.data(),
      } as FirestoreTrack
    })

    callback(tracks)
  })
}
