export class Currency {
  constructor(public fromUSD: number, public type: string, public name: string) {}
}

export class PaymentConfig {
  constructor(
    public trackPriceUSD: number,
    public artistCut: number,
    public supportedCurrencies: Currency[]
  ) {}
}
