import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Form} from 'antd'
import AlbumForm, {FormData} from '../sharedComponents/Forms/AlbumForm'
import {Helmet} from 'react-helmet'
import HOCGeneralUI from '../sharedComponents/UI/HOCGeneralUI'
import {$, textStore} from '../stores/localization/localization'
import {userStore} from '../stores/userStore'
import {createAlbum, createEmptyAlbum, getNewAlbumId} from '../fetchers/albumFetchers'
import {displayError, displaySuccess} from '../utils/displayNotification'
import {AlbumState, SELECTED_ACTION} from '../const/album'
import AlbumFormBottom from '../sharedComponents/Forms/AlbumFormBottom'
import {updateTracksWhenCreatingAlbum} from '../fetchers/trackFetchers'
import firebase from 'firebase'
import useAsyncEffect from 'use-async-effect'

const AlbumCreate = () => {
  const history = useHistory()
  const formRef = Form.useForm<FormData>()[0]
  const {key} = textStore()
  const {user} = userStore()
  const [publishLoading, setPublishLoading] = useState<boolean>(false)
  const [selectedAction, setSelectedAction] = useState<SELECTED_ACTION>()
  const [coverUrl, setCoverUrl] = useState<string | undefined>()

  const [albumId, setAlbumId] = useState<string>()

  useAsyncEffect(async () => {
    const id = getNewAlbumId()
    await createEmptyAlbum(id)
    setAlbumId(id)
  }, [])

  const onSubmit = async () => {
    if (selectedAction === SELECTED_ACTION.publish) {
      await onSave(AlbumState.published)
    } else {
      await onSave()
    }
  }

  const onPublish = async () => {
    setSelectedAction(SELECTED_ACTION.publish)
    await formRef.submit()
  }

  const onSave = async (newAlbumState?: AlbumState) => {
    try {
      if (albumId) {
        setPublishLoading(true)

        const {name, releaseYear, isSingle = null} = formRef.getFieldsValue()
        const payload = {
          name,
          releaseYear,
          isSingle,
          artist: {...user},
          albumState: newAlbumState ?? AlbumState.saved,
          createdAt: firebase.firestore.Timestamp.now(),
        }

        await createAlbum(albumId, payload)
        await updateTracksWhenCreatingAlbum(albumId, payload)
        displaySuccess($(key.success_title), $(key.music_album_create_success))
        history.goBack()
      } else {
        displayError($(key.error_title), $(key.artist_login_error_title))
      }
    } catch (e) {
      displayError($(key.error_title), $(key.error_text_data_missing))
    } finally {
      setPublishLoading(false)
    }
  }

  const onCancel = () => {
    history.goBack()
  }

  const handleOnSave = async () => {
    setSelectedAction(SELECTED_ACTION.save)
    await onSave()
  }

  return (
    <>
      <Helmet>
        <title>{$(key.page_title_artist_album)}</title>
      </Helmet>
      <HOCGeneralUI title={$(key.music_album_create_title_main)}>
        {albumId && (
          <>
            <AlbumForm
              albumId={albumId}
              formRef={formRef}
              onSubmit={onSubmit}
              coverImage={coverUrl}
              setCoverImage={setCoverUrl}
            />
            <AlbumFormBottom
              onPublish={onPublish}
              onSave={handleOnSave}
              onCancel={onCancel}
              publishLoading={publishLoading}
            />
          </>
        )}
      </HOCGeneralUI>
    </>
  )
}

export default AlbumCreate
