import React from 'react'
import RenderMenuItemLink from './RenderMenuItemLink'
import {$, textStore} from '../../../stores/localization/localization'
import {Button} from 'antd'
import {auth} from '../../../utils/firebase'
import {ROUTE_MANAGE_ARTISTS} from '../../../Routes'

const AdminMenu = () => {
  const {key} = textStore()

  const onLogout = async () => {
    await auth.signOut()
  }

  return (
    <>
      <RenderMenuItemLink linkURL={ROUTE_MANAGE_ARTISTS} title={$(key.artist_manager_title_main)} />
      <Button type='link' onClick={onLogout}>
        <strong>{$(key.login_title_logout)}</strong>
      </Button>
    </>
  )
}

export default AdminMenu
