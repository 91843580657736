export enum UserType {
  Admin = 'admin',
  Artist = 'artist',
  Customer = 'customer',
}

export enum SETUP_STEP {
  resetPassword,
  editProfile,
  finish,
}

export interface FirestoreUser {
  id: string
  firstName: string
  lastName: string
  type: UserType
  email: string
  photoURL: string
  setupStep?: number
  loginToken?: string
  paymentMethod?: string
  currency?: string
  customerNumber?: string
  artistReference?: string
  purchaseHistory: string[]
  playlists: string[]
  playHistory: string[]
}

export interface SessionUser {
  id: string
  email: string
  emailVerified: boolean
  displayName: string
  firstName: string
  lastName: string
  setupStep?: SETUP_STEP
  type: UserType
  paymentMethod?: string
  currency?: string
  customerNumber?: string
  artistReference?: string
  purchaseHistory: string[]
  playlists: string[]
  playHistory: string[]
  artistPhoto?: string
}
