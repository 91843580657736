import React, {ComponentType} from 'react'
import {Redirect, Route, useRouteMatch} from 'react-router-dom'
import {userStore} from '../../stores/userStore'
import {END_SETUP_STEP} from '../../const/artist'
import {UserType} from '../../models/toRefactor/User'
import Loading from '../../sharedComponents/Loading/Loading'
import {
  ROUTE_CUSTOMER,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_MANAGE_ARTISTS,
  ROUTE_NOT_FOUND,
  ROUTE_SETUP,
} from '../../Routes'

interface Props {
  component: any
  userTypesAllowed: UserType[]
  path: string
  exact?: boolean
}

const PrivateRoute = ({exact, component, userTypesAllowed, path}: Props) => {
  const {isLoggedIn, user} = userStore()
  const setupMatch = useRouteMatch(ROUTE_SETUP ?? ROUTE_NOT_FOUND)

  if (!isLoggedIn) {
    return (
      <Route
        exact={exact}
        path={path}
        render={() => (
          <Redirect
            to={{
              pathname: ROUTE_LOGIN,
              state: {referrer: path},
            }}
          />
        )}
      />
    )
  }

  if (!user) {
    return <Loading />
  }

  if (user.type === UserType.Artist && (user.setupStep ?? 0) < END_SETUP_STEP) {
    if (!setupMatch || !setupMatch.isExact) {
      return (
        <Route
          exact={exact}
          path={path}
          render={() => (
            <Redirect
              to={{
                pathname: ROUTE_SETUP,
                state: {referrer: path},
              }}
            />
          )}
        />
      )
    }
  }

  const defaultRedirectTo = () => {
    switch (user.type) {
      case UserType.Admin:
        return ROUTE_MANAGE_ARTISTS
      case UserType.Artist:
        return ROUTE_HOME
      case UserType.Customer:
      default:
        return ROUTE_CUSTOMER
    }
  }

  if (userTypesAllowed.indexOf(user.type) < 0) {
    return (
      <Route
        exact={exact}
        path={path}
        render={() => (
          <Redirect
            to={{
              pathname: defaultRedirectTo(),
              state: {referrer: path},
            }}
          />
        )}
      />
    )
  }

  return <Route exact={exact} path={path} component={component} />
}

export default PrivateRoute
