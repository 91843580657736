import React from 'react'
import {useHistory} from 'react-router-dom'
import {Col, Grid, Row} from 'antd'
import ArtistMenu from './components/ArtistMenu'
import AdminMenu from './components/AdminMenu'
import CustomerMenu from './components/CustomerMenu'
import RenderMenuItemLink from './components/RenderMenuItemLink'
import {UserType} from '../../models/toRefactor/User'
import {$, textStore} from '../../stores/localization/localization'
import {userStore} from '../../stores/userStore'
import {ReactComponent as Logo} from '../../assets/logo.svg'
import './navBar.less'
import {ROUTE_HOME, ROUTE_LOGIN} from '../../Routes'

const {useBreakpoint} = Grid

const NavBar = () => {
  const {isLoggedIn, user} = userStore()
  const {key} = textStore()
  const history = useHistory()
  const screen = useBreakpoint()

  const clickLogo = () => {
    history.push(ROUTE_HOME)
  }

  const renderLogin = () => (
    <RenderMenuItemLink linkURL={ROUTE_LOGIN} title={$(key.navbar_connect)} />
  )

  const renderLoggedIn = () => (
    <Row gutter={[30, 0]} justify='center' align='middle'>
      {user?.type === UserType.Artist && <ArtistMenu />}
      {user?.type === UserType.Admin && <AdminMenu />}
      {user?.type === UserType.Customer && <CustomerMenu />}
    </Row>
  )

  return (
    <Row className='navbar' justify='space-between' align='middle' gutter={[0, 0]}>
      <Col>
        <Logo width={100} height={64} onClick={clickLogo} />
      </Col>
      <Col style={!screen.md ? {width: '100%'} : {}}>
        {isLoggedIn ? renderLoggedIn() : renderLogin()}
      </Col>
    </Row>
  )
}

export default NavBar
