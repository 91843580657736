import React, {useState} from 'react'
import {Col, Dropdown, Menu, Row, Table, Typography} from 'antd'
import {CheckCircleOutlined, ClockCircleOutlined, MoreOutlined} from '@ant-design/icons'
import {ColumnsType} from 'antd/lib/table/interface'
import {FirestoreArtist} from '../../../models/toRefactor/Artist'
import {$, textStore} from '../../../stores/localization/localization'
import {renderFullName} from '../../../utils/helpers/naming'
import {END_SETUP_STEP} from '../../../const/artist'
import {resendEmailArtistAccountCreation} from '../../../fetchers/backendFetcher'
import useAsyncEffect from 'use-async-effect'
import {getAllArtists} from '../../../fetchers/artistFetchers'
import {displaySuccess} from '../../../utils/displayNotification'

const ArtistTable = () => {
  const {key} = textStore()

  const [artists, setArtists] = useState<[] | FirestoreArtist[]>()

  useAsyncEffect(async () => {
    await getAllArtists(allArtists => {
      setArtists(allArtists)
    })
  }, [])

  const handleResendClick = async (user: FirestoreArtist) => {
    await resendEmailArtistAccountCreation(user.email)
    displaySuccess($(key.success_title), $(key.artist_email_sent))
  }

  const menu = (user: FirestoreArtist) => (
    <Menu className='dropdown-menu-on-boarding'>
      <Menu.Item key='resend' onClick={() => handleResendClick(user)}>
        <Typography.Text className='text-medium-normal'>
          {$(key.artist_menu_action_resend)}
        </Typography.Text>
      </Menu.Item>
    </Menu>
  )

  const columns: ColumnsType<FirestoreArtist> = [
    {
      title: $(key.artist_manager_title_full_name),
      dataIndex: 'fullName',
      fixed: 'left',
      render: (value, record) => renderFullName(record.firstName, record.lastName),
      sorter: (a, b) => {
        const nameA = renderFullName(a.firstName, a.lastName)
        const nameB = renderFullName(b.firstName, b.lastName)
        return nameA.localeCompare(nameB)
      },
    },
    {
      title: $(key.artist_name),
      dataIndex: 'name',
      sorter: (a, b) => a.artistName.localeCompare(b.artistName),
    },
    {
      title: $(key.email),
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: $(key.artist_manager_title_artist_claimed),
      width: 1,
      dataIndex: 'setupStep',
      render: (step: number) => (
        <Row justify='center'>
          <Col>
            {step >= END_SETUP_STEP ? (
              <CheckCircleOutlined style={{color: 'green'}} />
            ) : (
              <ClockCircleOutlined style={{color: 'orange'}} />
            )}
          </Col>
        </Row>
      ),
    },
    {
      title: $(key.artist_menu_actions),
      key: 'action',
      render: (text, record) => (
        <Dropdown overlay={menu(record)}>
          <div>
            <MoreOutlined />
          </div>
        </Dropdown>
      ),
    },
  ]

  return (
    <Table scroll={{x: true}} columns={columns} dataSource={artists} rowKey={record => record.id} />
  )
}

export default ArtistTable
