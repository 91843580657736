import React, {useState} from 'react'
import {Button, Card, Col, Form, Input, Row, Space, Typography} from 'antd'
import {LoginState} from '../Login'
import {auth} from '../../../utils/firebase'
import {$, textStore} from '../../../stores/localization/localization'
import {ReactComponent as Key} from '../../../assets/key.svg'
import '../login.less'

interface FormData {
  email: string
  password: string
}

interface Props {
  onChangeLoginState: (state: LoginState, data: any) => void
  onError: (error: string) => void
}

const PasswordLogin = ({onChangeLoginState, onError}: Props) => {
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const {key} = textStore()

  const submit = async (data: FormData) => {
    setButtonLoading(true)

    try {
      await auth.signInWithEmailAndPassword(data.email, data.password)
    } catch (error: any) {
      if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
        onError($(key.login_text_credentials_incorrect))
      } else {
        onError($(key.login_text_unknown_error))
      }
    }

    setButtonLoading(false)
  }

  const verificationFailed = () => {
    onError($(key.error_text_data_missing))
  }

  return (
    <Row justify='center' gutter={[0, 15]}>
      <Col>
        <Typography.Title level={3}>{$(key.login_title_login)}</Typography.Title>
      </Col>
      <Col span={24}>
        <Card bordered={false}>
          <Form onFinish={submit} layout='vertical' onFinishFailed={verificationFailed}>
            <Space direction='vertical' size='large' className='form-space'>
              <Typography.Text>{$(key.login_text_login)}</Typography.Text>
              <Form.Item
                name='email'
                label={$(key.email)}
                rules={[
                  {
                    required: true,
                    message: $(key.login_text_email_missing),
                  },
                  {
                    type: 'email',
                    message: $(key.login_text_email_invalid),
                  },
                ]}>
                <Input autoComplete='username' />
              </Form.Item>
              <Form.Item
                name='password'
                label={$(key.password)}
                rules={[
                  {
                    required: true,
                    message: $(key.login_text_password_missing),
                  },
                ]}>
                <Input.Password autoComplete='current-password' />
              </Form.Item>
              <Form.Item>
                <Button type='primary' htmlType='submit' loading={buttonLoading}>
                  <Space size={5}>
                    <Key fill='#414141' height={12} width={12} /> {$(key.login_connect)}
                  </Space>
                </Button>
              </Form.Item>
            </Space>
          </Form>
          <Button type='link' onClick={() => onChangeLoginState(LoginState.forgotPassword, null)}>
            {$(key.login_forgot_password_goto)}
          </Button>
        </Card>
      </Col>
    </Row>
  )
}

export default PasswordLogin
