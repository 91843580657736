import {Button, Col, Form, Input, Space} from 'antd'
import {$, textStore} from '../../stores/localization/localization'
import {CheckOutlined} from '@ant-design/icons'
import {userStore} from '../../stores/userStore'

interface FormData {
  firstName: string
  lastName: string
  email: string
}

interface Props {
  loading: boolean
  submit: (data: FormData) => void
}

const UserProfileForm = ({loading, submit}: Props) => {
  const {key} = textStore()
  const {user} = userStore()

  return (
    <Col span={18}>
      {user && (
        <Form
          className='user-profile-form'
          onFinish={submit}
          layout='vertical'
          requiredMark={false}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}>
          <Form.Item
            label={$(key.first_name)}
            name='firstName'
            initialValue={user.firstName}
            rules={[{required: true, message: $(key.user_account_first_name_error)}]}>
            <Input placeholder={$(key.first_name)} />
          </Form.Item>
          <Form.Item
            label={$(key.last_name)}
            name='lastName'
            initialValue={user.lastName}
            rules={[{required: true, message: $(key.user_account_last_name_error)}]}>
            <Input placeholder={$(key.last_name)} />
          </Form.Item>
          <Form.Item
            label={$(key.email)}
            name='email'
            initialValue={user.email}
            rules={[{required: true, message: $(key.user_account_email_name_error)}]}>
            <Input placeholder={$(key.email)} />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit' loading={loading}>
              <Space>
                <CheckOutlined />
                {$(key.user_account_edit_button)}
              </Space>
            </Button>
          </Form.Item>
        </Form>
      )}
    </Col>
  )
}

export default UserProfileForm
