import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Alert, Button, Col, Divider, Row, Space, Typography} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import useAsyncEffect from 'use-async-effect'
import RenderTable from './components/RenderTable'
import HOCGeneralUI from '../../sharedComponents/UI/HOCGeneralUI'
import {$, textStore} from '../../stores/localization/localization'
import {userStore} from '../../stores/userStore'
import {FirestoreAlbum} from '../../models/toRefactor/Album'
import './albums.less'
import {Helmet} from 'react-helmet'
import {subscribeToArtistAlbum} from '../../fetchers/albumFetchers'
import {ROUTE_ADD_ALBUM} from '../../Routes'

const Albums = () => {
  const history = useHistory()
  const {key} = textStore()
  const {user} = userStore()
  const [values, setValues] = useState<FirestoreAlbum[]>()

  useAsyncEffect(
    async isActive => {
      if (!(user && user.id)) return

      await subscribeToArtistAlbum(user.id, albums => {
        if (!isActive()) return
        setValues(albums)
      })
    },
    [user]
  )

  const clickAddAlbum = () => {
    history.push(ROUTE_ADD_ALBUM)
  }

  return (
    <HOCGeneralUI title={$(key.music_album_title_main)}>
      <Row className='album-manager-container'>
        <Helmet>
          <title>{$(key.page_title_artist_music)}</title>
        </Helmet>
        <Col span={24}>
          <Row gutter={[0, 20]}>
            <Space direction='vertical' size={5}>
              <Typography.Paragraph>{$(key.music_album_text_instructions_1)}</Typography.Paragraph>
              <Typography.Paragraph>{$(key.music_album_text_instructions_2)}</Typography.Paragraph>
              <Alert message={$(key.music_album_text_conditions)} type='success' />
            </Space>
          </Row>
          <Divider />
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Typography.Text strong>{$(key.music_album_text_albums)}</Typography.Text>
            </Col>
            <Col span={24}>
              <Button type='primary' onClick={clickAddAlbum}>
                <PlusOutlined />
                {$(key.music_album_add_album)}
              </Button>
            </Col>
            <Col span={24}>
              <RenderTable values={values} />
            </Col>
          </Row>
        </Col>
      </Row>
    </HOCGeneralUI>
  )
}

export default Albums
