import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/remote-config'

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
})

const db = app.firestore()
db.enablePersistence().catch(error => console.error(error))
const mainStorage = firebase.storage()
const profilePictureStorage = firebase
  .app()
  .storage(`gs://${process.env.REACT_APP_PROJECTID}-artists`)
const albumPictureStorage = firebase.app().storage(`gs://${process.env.REACT_APP_PROJECTID}-albums`)
const trackStorage = firebase.app().storage(`gs://${process.env.REACT_APP_PROJECTID}-tracks`)
const auth = firebase.auth()
auth.useDeviceLanguage()
const functions = firebase.functions()
const remoteConfig = firebase.remoteConfig()

if (process.env.NODE_ENV === 'development') {
  firebase.functions().useEmulator('localhost', 5001)
}

export {
  app,
  auth,
  functions,
  mainStorage,
  trackStorage,
  albumPictureStorage,
  profilePictureStorage,
  db,
  remoteConfig,
}
