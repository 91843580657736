import {useHistory, useParams} from 'react-router-dom'
import useAsyncEffect from 'use-async-effect'
import {useState} from 'react'
import {auth} from '../../utils/firebase'
import Loading from '../../sharedComponents/Loading/Loading'
import LoginError from './components/LoginError'
import {ROUTE_SETUP} from '../../Routes'
import {fetchUserWihToken, removeLoginToken} from '../../fetchers/usersFetcher'

interface IParams {
  token: string
}

const LoginUUID = () => {
  const [error, setError] = useState<boolean>(false)
  const {token} = useParams<IParams>()

  const history = useHistory()

  useAsyncEffect(async () => {
    const user = await fetchUserWihToken(token)

    if (user && user.loginToken) {
      auth
        .signInWithCustomToken(user.loginToken)
        .then(async () => {
          await removeLoginToken(user.id)
          history.push(ROUTE_SETUP)
        })
        .catch(() => {
          setError(true)
        })
    } else {
      setError(true)
    }
  }, [])

  return error ? <LoginError /> : <Loading />
}

export default LoginUUID
