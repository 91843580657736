import {db, profilePictureStorage} from '../utils/firebase'
import {USERS_COLLECTION} from '../const/db'
import {FirestoreUser} from '../models/toRefactor/User'

const usersColRef = db.collection(USERS_COLLECTION)

export const updateUserWithId = async (uid: string, payload: any) => {
  return usersColRef.doc(uid).update(payload)
}

export const removeLoginToken = async (uid: string) => {
  await usersColRef.doc(uid).set({loginToken: null}, {merge: true})
}

export const addUserProfilePicture = (uid: string, file: Blob) => {
  const storageRef = profilePictureStorage.ref(`${uid}/main`)
  return storageRef.put(file)
}

export const fetchUserWihToken = async (token: string): Promise<FirestoreUser | undefined> => {
  const result = await usersColRef.where('loginToken', '==', token).get()
  let user: FirestoreUser | undefined = undefined

  if (result.size === 1) {
    result.forEach(res => {
      user = {...res.data(), id: res.id} as FirestoreUser
    })
  }
  return user
}

export const subscribeToUserWithId = async (uid: string, callback: (snapshot: any) => void) => {
  return usersColRef.doc(uid).onSnapshot(callback)
}
