import React, {useState} from 'react'
import {Button, Card, Col, Form, Input, Result, Row, Space, Typography} from 'antd'
import {LoginState} from '../Login'
import {auth} from '../../../utils/firebase'
import {$, key} from '../../../stores/localization/localization'
import '../login.less'

interface Props {
  stateData: any
  onChangeLoginState: (state: LoginState, data: any) => void
  onError: (error: string) => void
}

const RecoverPassword = ({stateData, onChangeLoginState, onError}: Props) => {
  const [recoverEmail, setRecoverEmail] = useState<string | null>()

  const sendRecoverEmail = async (formData: {email: string}) => {
    try {
      await auth.sendPasswordResetEmail(formData.email)
      setRecoverEmail(formData.email)
    } catch (error: any) {
      if (error.code === 'auth/user-not-found') {
        setRecoverEmail(formData.email)
      } else {
        onError($(key.login_text_recover_password_failed))
      }
    }
  }

  const renderFormEmailRecover = () => {
    return (
      <Row justify='center' gutter={[0, 15]}>
        <Col>
          <Typography.Title level={3}>{$(key.login_title_recover_password)}</Typography.Title>
        </Col>
        <Col span={24}>
          <Card bordered={false}>
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Typography.Text>{$(key.login_text_recover_password)}</Typography.Text>
              </Col>
              <Col span={24}>
                <Form
                  name='formLoginEmail'
                  className='form-login-email'
                  layout='vertical'
                  hideRequiredMark={true}
                  colon={false}
                  onFinish={sendRecoverEmail}
                  initialValues={stateData}>
                  <Form.Item
                    name='email'
                    rules={[
                      {
                        type: 'email',
                        message: `${$(key.login_text_email_invalid)}`,
                      },
                      {
                        required: true,
                        message: `${$(key.login_text_email_missing)}`,
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Row justify='end'>
                      <Space>
                        <Button
                          type='default'
                          className='input-recover-cancel'
                          onClick={() => onChangeLoginState(LoginState.login, null)}>
                          {$(key.cancel)}
                        </Button>
                        <Button type='primary' className='input-recover-send' htmlType='submit'>
                          {$(key.send)}
                        </Button>
                      </Space>
                    </Row>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }

  const renderRecoverEmailSent = () => {
    return (
      <Row justify='center' gutter={[0, 15]}>
        <Col span={24}>
          <Result
            status='success'
            title={$(key.login_title_check_email)}
            subTitle={$(key.login_text_recover_password_success, recoverEmail)}
            extra={
              <Button type='primary' onClick={() => onChangeLoginState(LoginState.login, null)}>
                {$(key.home)}
              </Button>
            }
          />
        </Col>
      </Row>
    )
  }

  if (recoverEmail) {
    return renderRecoverEmailSent()
  } else {
    return renderFormEmailRecover()
  }
}

export default RecoverPassword
