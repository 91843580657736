import axios from 'axios'

export const requestCompensation = (
  displayName: string,
  email: string,
  date: Date,
  saleNumber: number,
  amount: number
) => {
  return axios({
    method: 'post',
    url: `${process.env.REACT_APP_FUNCTION_URL}emailArtistRequestCompensation`,
    data: {displayName, email, date, saleNumber, amount},
  })
}

export const resendEmailArtistAccountCreation = (email: string) => {
  return axios({
    method: 'POST',
    url: `${process.env.REACT_APP_FUNCTION_URL}resendEmailArtistAccountCreation`,
    data: {email},
  })
}

export const createArtistAccount = async (
  artistName: string,
  email: string,
  firstName: string,
  lastName: string
) => {
  return await axios.post(`${process.env.REACT_APP_FUNCTION_URL}createArtistAccount`, {
    artistName,
    email,
    firstName,
    lastName,
  })
}
