import React, {useEffect, useState} from 'react'
import {Redirect} from 'react-router-dom'
import {Col, Row} from 'antd'
import PasswordLogin from './components/PasswordLogin'
import RecoverPassword from './components/RecoverPassword'
import {$, textStore} from '../../stores/localization/localization'
import {userStore} from '../../stores/userStore'
import './login.less'
import {LOCAL_STORAGE_RESET_PASSWORD, ROUTE_HOME} from '../../Routes'
import {Helmet} from 'react-helmet'
import {displayError} from '../../utils/displayNotification'

export enum LoginState {
  login,
  forgotPassword,
}

const Login = () => {
  const [currLoginState, setCurrentLoginState] = useState<LoginState>(LoginState.login)
  const [currStateData, setCurrStateData] = useState<any>(null)
  const {isLoggedIn} = userStore()
  const {key} = textStore()

  useEffect(() => {
    const reset = localStorage.getItem(LOCAL_STORAGE_RESET_PASSWORD)
    if (reset) {
      setCurrentLoginState(LoginState.forgotPassword)
    }
    localStorage.removeItem(LOCAL_STORAGE_RESET_PASSWORD)
  }, [])

  const showError = (error: string) => {
    displayError($(key.error_title), error)
  }

  const changeLoginState = (newState: LoginState, data: any) => {
    setCurrStateData(data)
    setCurrentLoginState(newState)
  }

  const renderLoginState = () => {
    switch (currLoginState) {
      case LoginState.forgotPassword:
        return (
          <>
            <Helmet>
              <title>{$(key.page_title_forgot_password)}</title>
            </Helmet>
            <RecoverPassword
              stateData={currStateData}
              onChangeLoginState={changeLoginState}
              onError={showError}
            />
          </>
        )
      case LoginState.login:
      default:
        return <PasswordLogin onChangeLoginState={changeLoginState} onError={showError} />
    }
  }

  if (isLoggedIn) {
    return <Redirect to={ROUTE_HOME} />
  }

  return (
    <Row justify='center' className='login-container'>
      <Helmet>
        <title>{$(key.page_title_login)}</title>
      </Helmet>
      <Col xxl={7} xl={10} md={12} sm={16} xs={24}>
        {renderLoginState()}
      </Col>
    </Row>
  )
}

export default Login
