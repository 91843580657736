import create from 'zustand'
import LocalizedStrings from 'react-localization'
import fr from './fr.json'

export const key = new LocalizedStrings({
  fr: fr,
})

export const textStore = create((set: any) => ({
  key: key,
  setLanguage: (lang: 'fr') =>
    set((state: any) => {
      const tempKey = state.key
      if (!tempKey.getAvailableLanguages().includes(lang)) return

      tempKey.setLanguage(lang)

      return {key: tempKey}
    }),
}))

export const $ = (stringKey: string, ...rest: any): string =>
  textStore.getState().key.formatString(stringKey, ...rest) as string
