export type albumState = AlbumState

export enum AlbumState {
  temp = 'temp',
  saved = 'saved',
  published = 'published',
  unpublished = 'unpublished',
}

export enum SELECTED_ACTION {
  publish,
  save,
}
