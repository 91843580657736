import React from 'react'
import {Button, Result, Row} from 'antd'
import {$, textStore} from '../../../stores/localization/localization'

const LoginError = () => {
  const {key} = textStore()

  return (
    <Result
      status='error'
      title={$(key.artist_login_error_title)}
      subTitle={$(key.artist_login_error_subtitle)}>
      <Row justify='center'>
        <Button href={`mailto:no-reply@yo-zik.comm?subject=${key.artist_login_error_mail_subject}`}>
          {$(key.artist_login_error_button_text)}
        </Button>
      </Row>
    </Result>
  )
}

export default LoginError
