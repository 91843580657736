import firebase from 'firebase/app'
import create from 'zustand'
import {FirestoreUser, SessionUser} from '../models/toRefactor/User'
import {subscribeToUserWithId} from '../fetchers/usersFetcher'

type State = {
  user: SessionUser | null
  isLoggedIn: boolean
  login: (user: firebase.User) => void
  logout: () => void
}

export const userStore = create<State>((set: any) => ({
  user: null,
  isLoggedIn: false,

  login: (authUser: firebase.User) => {
    set((state: State) => {
      if (state.isLoggedIn) return {}

      subscribeToUserWithId(authUser.uid, async userResult => {
        if (userResult.exists) {
          const storeUser: FirestoreUser = {
            ...userResult.data(),
            id: userResult.id,
          }

          set({
            user: {
              emailVerified: authUser.emailVerified ?? false,
              displayName: authUser.displayName ?? '',
              ...storeUser,
            },
          })
        }
      }).then()

      return {isLoggedIn: true}
    })
  },

  logout: () => {
    set(() => {
      return {user: null, isLoggedIn: false}
    })
  },
}))
