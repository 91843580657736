import {db} from '../utils/firebase'
import {USERS_COLLECTION} from '../const/db'
import {FirestoreArtist} from '../models/toRefactor/Artist'
import {UserType} from '../models/toRefactor/User'

const usersColRef = db.collection(USERS_COLLECTION)

export const getAllArtists = async (callback: (res: FirestoreArtist[]) => void) => {
  return usersColRef.where('type', '==', UserType.Artist).onSnapshot(snapshot => {
    if (snapshot.empty) {
      callback([])
      return
    }

    const artists = snapshot.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
      } as FirestoreArtist
    })

    callback(artists)
  })
}
