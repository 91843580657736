import {Button, Col, Divider, Row, Space, Typography} from 'antd'
import {$, textStore} from '../../stores/localization/localization'
import Loading from '../../sharedComponents/Loading/Loading'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import yellowArrowRight from '../../assets/yellowArrowRight.svg'
import React, {useState} from 'react'
import {ROUTE_COMPENSATION_DETAILS} from '../../Routes'
import {useHistory} from 'react-router-dom'
import useAsyncEffect from 'use-async-effect'
import {fetchCompensation} from '../../fetchers/compensationFetcher'
import groupBy from 'lodash/groupBy'
import {PaymentConfig} from '../../models/Config'

interface Props {
  paymentConfig: PaymentConfig | undefined
}

const CompensationList = ({paymentConfig}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [monthsGroups, setMonthGroups] = useState<any>()

  const history = useHistory()
  const {key} = textStore()

  useAsyncEffect(async isActive => {
    setIsLoading(true)

    const compensationData = await fetchCompensation()
    if (!isActive()) return

    if (compensationData) {
      const orderedResults = compensationData.sort(
        (a, b) => a.createdAt.toMillis() - b.createdAt.toMillis()
      )

      const resultsByMonths = groupBy(orderedResults, item => {
        return dayjs(item.createdAt.toDate()).startOf('month').format()
      })

      setMonthGroups(resultsByMonths)
    }
    setIsLoading(false)
  }, [])

  const handleDetailsClick = (date: string) => {
    history.push(ROUTE_COMPENSATION_DETAILS, {selectedMonth: monthsGroups[date], date})
  }

  return (
    <Row gutter={[0, 10]} className='compensation-container'>
      <Divider />
      <Col span={24}>
        <Typography.Title level={5}>{$(key.compensation_summary)}</Typography.Title>
      </Col>
      {isLoading && <Loading />}

      {paymentConfig && monthsGroups && !isEmpty(paymentConfig) && !isEmpty(monthsGroups) ? (
        <>
          {Object.keys(monthsGroups)
            .reverse()
            .map((item, index) => {
              const date = new Date(item)
              const monthSale = monthsGroups[item].length
              const salesText = `${monthSale} ${$(key.compensation_sale_text)} / $${
                monthSale * (paymentConfig.trackPriceUSD * paymentConfig.artistCut)
              }`

              return (
                <Row key={index} className='full-row'>
                  <Col span={8} key={index}>
                    <Typography.Text strong>{dayjs(date).format('MMMM YYYY')}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Typography.Text>{salesText}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Row justify='end'>
                      <Button type='link' onClick={() => handleDetailsClick(item)}>
                        <Space>
                          {$(key.compensation_details)}
                          <img src={yellowArrowRight} alt={$(key.compensation_details)} />
                        </Space>
                      </Button>
                    </Row>
                  </Col>
                </Row>
              )
            })}
        </>
      ) : (
        <Col span={24}>
          <Typography.Text>{$(key.compensation_no_compensation_yet)}</Typography.Text>
        </Col>
      )}
    </Row>
  )
}

export default CompensationList
