import React, {useEffect, useState} from 'react'
import {Card, Col, Row, Steps} from 'antd'
import Step0 from './components/Step0'
import Step1 from './components/Step1'
import Step2 from './components/Step2'
import {$, textStore} from '../../stores/localization/localization'
import {userStore} from '../../stores/userStore'
import HOCGeneralUI from '../../sharedComponents/UI/HOCGeneralUI'
import {SETUP_STEP} from '../../models/toRefactor/User'
import {SolutionOutlined, UserOutlined} from '@ant-design/icons'
import {updateUserWithId} from '../../fetchers/usersFetcher'

const {Step} = Steps

const Setup = () => {
  const {key} = textStore()
  const {user} = userStore()

  const [setupStep, setSetupStep] = useState<SETUP_STEP>(SETUP_STEP.resetPassword)

  useEffect(() => {
    if (user) {
      setSetupStep(user.setupStep ?? SETUP_STEP.resetPassword)
    }
  }, [])

  const calculateStatus = (step: SETUP_STEP) => {
    return setupStep > step ? 'finish' : setupStep === step ? 'process' : 'wait'
  }

  const setStep = async (step: SETUP_STEP) => {
    if (user) {
      setSetupStep(step)
      await updateUserWithId(user.id, {setupStep: step})
    }
  }

  return (
    <HOCGeneralUI title={$(key.setup_title_main, user?.firstName ?? '')}>
      <Row className='setup-container'>
        <Col span={6}>
          <Steps direction='vertical' current={setupStep}>
            <Step
              title={$(key.setup_step0)}
              status={calculateStatus(SETUP_STEP.resetPassword)}
              icon={setupStep >= SETUP_STEP.resetPassword && <UserOutlined />}
            />
            <Step
              title={$(key.setup_step1)}
              status={calculateStatus(SETUP_STEP.editProfile)}
              icon={setupStep >= 1 && <SolutionOutlined />}
            />
            <Step
              title={$(key.setup_step2)}
              status={calculateStatus(SETUP_STEP.finish)}
              icon={setupStep >= 2 && <SolutionOutlined />}
            />
          </Steps>
        </Col>
        <Col span={18}>
          <Card>
            {setupStep === SETUP_STEP.resetPassword && <Step0 setStep={setStep} />}
            {setupStep === SETUP_STEP.editProfile && <Step1 setStep={setStep} />}
            {setupStep === SETUP_STEP.finish && <Step2 />}
          </Card>
        </Col>
      </Row>
    </HOCGeneralUI>
  )
}

export default Setup
