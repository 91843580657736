import {Button, Modal, Row, Space, Spin, Typography, Upload} from 'antd'
import {DeleteOutlined, EyeOutlined, PlusOutlined} from '@ant-design/icons'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {RcFile} from 'antd/es/upload'
import ReactCrop, {Crop} from 'react-image-crop'
import {toBase64} from '../../utils/toBase64'
import {getCroppedImg} from '../../utils/getCroppedImage'

import 'react-image-crop/dist/ReactCrop.css'
import './uploadImage.less'

interface UploadProps {
  uploading: boolean
  pictureUrl: string | undefined
  handleImageUpload: (img: RcFile) => void
  handleDelete: () => void
}

const UploadImage = ({uploading, pictureUrl, handleImageUpload, handleDelete}: UploadProps) => {
  const initCrop: Crop = {x: 0, y: 0, height: 150, width: 150, aspect: 1}

  const [previewVisible, setPreviewVisible] = useState(false)
  const [cropModalVisible, setCropModalVisible] = useState(false)
  const [image, setImage] = useState<File | undefined>(undefined)
  const [crop, setCrop] = useState<Crop>(initCrop)
  const [file, setFile] = useState<any>()
  const imgRef = useRef(null)

  useEffect(() => {
    setCrop(initCrop)
  }, [pictureUrl])

  const handleBeforeUpload = async (inputFile: File) => {
    setImage(inputFile)
    setCropModalVisible(true)
    const base64 = await toBase64(inputFile)
    setFile(base64)
  }

  const handleCropping = async () => {
    if (image) {
      const croppedImg = await getCroppedImg(imgRef.current, crop, image?.name ?? '')

      setImage(croppedImg)
      setCropModalVisible(false)
      handleImageUpload(croppedImg)
    }
  }

  const DisplayImage = () => {
    return (
      <div className='image-container'>
        <img src={pictureUrl} className='image-profile-picture' alt='uploadImage' />
        <div className='overlay'>
          <div className='icon-upload-image-container'>
            <DeleteOutlined className='icon-upload-image' onClick={() => handleDelete()} />
            <EyeOutlined className='icon-upload-image' onClick={() => setPreviewVisible(true)} />
          </div>
        </div>
      </div>
    )
  }

  const onImageLoaded = useCallback(img => {
    imgRef.current = img
  }, [])

  return (
    <>
      {file && (
        <Modal
          visible={cropModalVisible}
          onCancel={() => setCropModalVisible(false)}
          onOk={handleCropping}
          closable={false}
          maskClosable={false}
          className='image-crop-container'>
          <ReactCrop src={file} crop={crop} onChange={setCrop} onImageLoaded={onImageLoaded} />
        </Modal>
      )}
      <Modal visible={previewVisible} closable={false} footer={null}>
        <Space direction='vertical' size={20} style={{width: '100%'}}>
          <img src={pictureUrl} style={{width: '100%'}} alt='cover' />
          <Row justify='end'>
            <Button type='primary' onClick={() => setPreviewVisible(false)}>
              Ok
            </Button>
          </Row>
        </Space>
      </Modal>
      <div className='upload-image-container'>
        <Row justify='center'>
          {pictureUrl ? (
            <DisplayImage />
          ) : (
            <>
              <Upload
                listType='picture-card'
                className='upload-image-button'
                showUploadList={false}
                multiple={false}
                beforeUpload={handleBeforeUpload}>
                {uploading ? (
                  <Spin />
                ) : (
                  <Space direction='vertical'>
                    <PlusOutlined />
                    <Typography.Text>Upload</Typography.Text>
                  </Space>
                )}
              </Upload>
            </>
          )}
        </Row>
      </div>
    </>
  )
}

export default UploadImage
