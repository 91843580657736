import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Modal, Table, Typography, Upload} from 'antd'
import {DeleteOutlined, EditOutlined, InboxOutlined} from '@ant-design/icons'
import {$, textStore} from '../../stores/localization/localization'
import './trackUpload.less'
import formatBytes from '../../utils/helpers/formatBytes'
import {FirestoreAlbum, FirestoreTrack} from '../../models/toRefactor/Album'
import {userStore} from '../../stores/userStore'
import {displayError} from '../../utils/displayNotification'
import {RcFile} from 'antd/es/upload'
import computeAudioFileLength from '../../utils/helpers/computeAudioFileLength'
import useAsyncEffect from 'use-async-effect'
import {getAlbumById} from '../../fetchers/albumFetchers'
import omit from 'lodash/omit'
import {
  addTrackToStorage,
  createTrack,
  deleteTrackWithId,
  subscribeToTracksWithAlbumId,
  updateTrackWithId,
} from '../../fetchers/trackFetchers'
import {AlbumState} from '../../const/album'

interface Props {
  albumId: string
  single: boolean
}

interface FormData {
  name: string
}

const TRACK_SIZE_LIMIT = 15 * 1024 * 1024
const TrackUpload = ({albumId, single}: Props) => {
  const [album, setAlbum] = useState<FirestoreAlbum | null>(null)
  const [trackList, setTrackList] = useState<FirestoreTrack[]>([])
  const [selectedTrack, setSelectedTrack] = useState<FirestoreTrack | undefined>(undefined)
  const [renameModalVisible, setRenameModalVisible] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [renameLoading, setRenameLoading] = useState<boolean>(false)
  const [uploading, setUploading] = useState<boolean>(false)

  const key = textStore(state => state.key)
  const renameFormRef = Form.useForm<FormData>()[0]
  const {user} = userStore()

  useEffect(() => {
    renameFormRef.resetFields()
  }, [selectedTrack])

  useAsyncEffect(
    async isActive => {
      if (!albumId) return

      const albumData = await getAlbumById(albumId)
      if (!isActive()) return

      setAlbum(albumData)
      if (album?.albumState) {
        setDisabled([AlbumState.published, AlbumState.unpublished].includes(album.albumState))
      }

      await subscribeToTracksWithAlbumId(albumId, res => {
        setTrackList(res)
      })
    },
    [albumId]
  )

  const beforeUpload = (file: RcFile) => {
    const smallEnough = file.size < TRACK_SIZE_LIMIT

    if (!smallEnough) {
      displayError($(key.error_title), $(key.track_upload_error_too_big))
      return false
    }

    if (file.type !== 'audio/mpeg') {
      displayError($(key.error_title), $(key.track_upload_error_format))
      return false
    }

    return true
  }

  const customRequest = async (proc: any) => {
    if (!user) return

    setUploading(true)

    const {file} = proc
    const music: any = await computeAudioFileLength(file)
    const cleanName = file.name.split('.').slice(0, -1).join('.')

    const tempTrack = {
      name: cleanName,
      size: file.size,
      duration: Math.ceil(music.duration),
      album: {
        id: albumId,
        ...omit(album, ['artist', 'id']),
      },
      artist: user,
    } as FirestoreTrack

    const track = await createTrack(tempTrack)
    const uploadTask = addTrackToStorage(user.id, albumId, track.id, file)
    if (!uploadTask) {
      setUploading(false)
      return
    }

    uploadTask.on(
      'state_changed',
      () => {},
      () => {
        setUploading(false)

        displayError($(key.error_title), $(key.track_upload_error))
      },
      async () => {
        setUploading(false)

        //Set upload object to finished to progress from the internal loading state
        if (proc.onSuccess) {
          const trackURL = await uploadTask.snapshot.ref.getDownloadURL()
          proc.onSuccess({}, {responseURL: trackURL} as XMLHttpRequest)
        }
      }
    )
  }
  const handleRenameClick = (item: FirestoreTrack) => {
    setSelectedTrack(item)
    setRenameModalVisible(true)
  }

  const handleDeleteFile = async (item: FirestoreTrack) => {
    setUploading(true)
    await deleteTrackWithId(item)
    setTrackList(trackList.filter(it => it.name !== item.name))
    setUploading(false)
  }
  const handleRenameSubmit = async (e: any) => {
    setRenameLoading(true)
    if (selectedTrack) {
      await updateTrackWithId(selectedTrack?.id, e)
      setRenameModalVisible(false)
      setRenameLoading(false)
    }
  }

  const columns = [
    {
      title: $(key.track_upload_name),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: $(key.track_upload_size),
      dataIndex: 'size',
      render: (size: number) => <>{formatBytes(size)}</>,
    },
    {
      title: $(key.track_upload_edit),
      dataIndex: 'edit',
      width: 1,
      render: (_: any, record: FirestoreTrack) => {
        return (
          <Button type='link' onClick={() => handleRenameClick(record)} disabled={disabled}>
            <EditOutlined />
          </Button>
        )
      },
    },
    {
      title: $(key.delete),
      dataIndex: 'delete',
      width: 1,
      render: (_: any, record: FirestoreTrack) => {
        return (
          <Button disabled={disabled} type='link' onClick={() => handleDeleteFile(record)}>
            <DeleteOutlined />
          </Button>
        )
      },
    },
  ]

  return (
    <Col span={24} className='track-upload-container'>
      <Upload.Dragger
        className='track-upload-drag'
        accept='audio/mpeg'
        showUploadList={false}
        beforeUpload={beforeUpload}
        multiple={!single}
        disabled={disabled}
        customRequest={customRequest}>
        <p className='ant-upload-drag-icon'>
          <InboxOutlined style={{color: disabled ? 'rgba(0, 0, 0, 0.25)' : '#ffd92e'}} />
        </p>
        <Typography.Title level={5}>{$(key.track_upload_drag_title)}</Typography.Title>
        <Typography.Paragraph>{$(key.track_upload_drag_subtitle)}</Typography.Paragraph>
      </Upload.Dragger>
      <Table
        loading={uploading}
        columns={columns}
        dataSource={trackList}
        rowKey='id'
        showSorterTooltip={true}
        pagination={false}
      />
      <Modal
        visible={renameModalVisible}
        onCancel={() => setRenameModalVisible(false)}
        title={$(key.track_upload_rename_title)}
        confirmLoading={renameLoading}
        onOk={() => renameFormRef.submit()}>
        <Form
          layout='vertical'
          form={renameFormRef}
          onFinish={handleRenameSubmit}
          initialValues={selectedTrack}>
          <Form.Item name='name' label={$(key.track_upload_rename_label)} required={true}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Col>
  )
}

export default TrackUpload
