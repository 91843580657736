import React, {useState} from 'react'
import {Button, Col, Modal, Row, Space, Typography} from 'antd'
import {$, textStore} from '../../stores/localization/localization'
import whiteCheck from '../../assets/check.svg'
import './albumFormBottom.less'

interface FormProps {
  onPublish: () => void
  onSave: () => void
  onCancel: () => void
  publishLoading: boolean
}

const AlbumFormBottom = ({onPublish, onSave, onCancel, publishLoading}: FormProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const {key} = textStore()

  return (
    <div className='album-form-bottom-container'>
      <Row justify='space-between'>
        <Col>
          <Row>
            <Col>
              <Button type='primary' onClick={onSave}>
                <Space>
                  <img src={whiteCheck} alt={$(key.save)} />
                  {$(key.save)}
                </Space>
              </Button>
            </Col>
            <Col>
              <Button type='link' onClick={onCancel}>
                {$(key.cancel)}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type='primary'
            className='publish-button'
            loading={publishLoading}
            onClick={() => setShowModal(true)}>
            {$(key.publish)}
          </Button>
        </Col>
      </Row>
      <Modal
        closable={false}
        visible={showModal}
        okText={$(key.publish)}
        cancelText={$(key.cancel)}
        onCancel={() => setShowModal(false)}
        onOk={() => {
          onPublish()
          setShowModal(false)
        }}>
        <Typography.Paragraph>{$(key.album_publish_modal_line_1)}</Typography.Paragraph>
        <Typography.Paragraph>{$(key.album_publish_modal_line_2)}</Typography.Paragraph>
        <Typography.Paragraph strong>{$(key.album_publish_modal_line_3)}</Typography.Paragraph>
        <ul>
          <li>
            <Typography.Text strong>{$(key.album_publish_modal_item_1)}</Typography.Text>
          </li>
          <li>
            <Typography.Text strong>{$(key.album_publish_modal_item_2)}</Typography.Text>
          </li>
          <li>
            <Typography.Text strong>{$(key.album_publish_modal_item_3)}</Typography.Text>
          </li>
        </ul>
      </Modal>
    </div>
  )
}

export default AlbumFormBottom
