import {Button, Row} from 'antd'
import {$, key} from '../../stores/localization/localization'

interface Props {
  onUnpublished: () => void
  loading: boolean
}

const AlbumFormPublished = ({onUnpublished, loading}: Props) => {
  return (
    <>
      <Row justify='end'>
        <Button onClick={onUnpublished} type='primary' danger loading={loading}>
          {$(key.unpublish)}
        </Button>
      </Row>
    </>
  )
}

export default AlbumFormPublished
