import React, {useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import useAsyncEffect from 'use-async-effect'
import HOCGeneralUI from '../sharedComponents/UI/HOCGeneralUI'
import {$, textStore} from '../stores/localization/localization'
import {Helmet} from 'react-helmet'
import {changeAlbumState, getAlbumById, updateAlbum} from '../fetchers/albumFetchers'
import {FirestoreAlbum} from '../models/toRefactor/Album'
import {AlbumState, SELECTED_ACTION} from '../const/album'
import {Alert, Col, Divider, Form, Space} from 'antd'
import AlbumForm, {FormData} from '../sharedComponents/Forms/AlbumForm'
import AlbumFormBottom from '../sharedComponents/Forms/AlbumFormBottom'
import AlbumFormPublished from '../sharedComponents/Forms/AlbumFormPublished'
import {displayError, displaySuccess} from '../utils/displayNotification'
import {userStore} from '../stores/userStore'

interface IParams {
  albumId: string
}

const AlbumEdit = () => {
  const history = useHistory()
  const [album, setAlbum] = useState<FirestoreAlbum | null>()
  const {albumId} = useParams<IParams>()
  const {key} = textStore()
  const formRef = Form.useForm<FormData>()[0]
  const {user} = userStore()
  const [publishLoading, setPublishLoading] = useState<boolean>(false)
  const [selectedAction, setSelectedAction] = useState<SELECTED_ACTION>()
  const [coverUrl, setCoverUrl] = useState<string | undefined>()

  useAsyncEffect(
    async isActive => {
      if (!albumId) return

      const albumData = await getAlbumById(albumId)
      if (!isActive()) return
      if (!albumData) history.goBack()

      setAlbum(albumData)
    },
    [albumId]
  )

  const onSubmit = async () => {
    if (selectedAction === SELECTED_ACTION.publish) {
      await onSave(AlbumState.published)
    } else {
      await onSave()
    }
  }

  const onPublish = async () => {
    setSelectedAction(SELECTED_ACTION.publish)
    await formRef.submit()
  }

  const onSave = async (newAlbumState?: AlbumState) => {
    try {
      if (album) {
        setPublishLoading(true)

        let albumState = newAlbumState ?? AlbumState.saved
        if (album.albumState === AlbumState.published) {
          albumState = AlbumState.published
        }

        const {name, releaseYear, isSingle = null} = formRef.getFieldsValue()
        const payload = {
          name,
          releaseYear,
          isSingle,
          artist: {...user},
          albumState: albumState,
        }
        await updateAlbum(album.id, payload)
        displaySuccess($(key.success_title), $(key.music_album_save_success))
        history.goBack()
      } else {
        displayError($(key.error_title), $(key.artist_login_error_title))
      }
    } catch (e) {
      displayError($(key.error_title), $(key.error_text_data_missing))
    } finally {
      setPublishLoading(false)
    }
  }

  const onCancel = () => {
    history.goBack()
  }

  const onUnPublish = async () => {
    setPublishLoading(true)
    await changeAlbumState(albumId, AlbumState.unpublished)
    displaySuccess($(key.success_title), $(key.music_album_unpublished_success))
    setPublishLoading(false)
    history.goBack()
  }

  const handleOnSave = async () => {
    setSelectedAction(SELECTED_ACTION.save)
    await onSave()
  }

  return (
    <>
      <Helmet>
        <title>{$(key.page_title_artist_album)}</title>
      </Helmet>
      <HOCGeneralUI title={$(key.music_album_edit_title_main)}>
        <Space direction='vertical' size={20} style={{width: '100%'}}>
          {album?.albumState === AlbumState.unpublished && (
            <Alert
              message={$(key.music_album_unpublished_alert)}
              showIcon
              type='error'
              style={{marginBottom: '16px'}}
            />
          )}
          {album?.albumState === AlbumState.published && (
            <>
              <Alert message={$(key.music_album_published_alert_1)} showIcon />
              <Alert
                style={{marginBottom: '16px'}}
                showIcon
                message={$(
                  key.music_album_published_alert_2,
                  <a href='mailto:support@yo-zik.com'>support@yo-zik.com</a>
                )}
              />
            </>
          )}
        </Space>
        {album?.id && (
          <>
            <AlbumForm
              setCoverImage={setCoverUrl}
              coverImage={coverUrl}
              albumId={album?.id}
              formRef={formRef}
              initialValues={album ?? undefined}
              onSubmit={onSubmit}
            />
            <>
              <Divider />
              <Col>
                {album?.albumState !== AlbumState.published ? (
                  <AlbumFormBottom
                    onPublish={onPublish}
                    onSave={handleOnSave}
                    onCancel={onCancel}
                    publishLoading={publishLoading}
                  />
                ) : (
                  <AlbumFormPublished onUnpublished={onUnPublish} loading={publishLoading} />
                )}
              </Col>
            </>
          </>
        )}
      </HOCGeneralUI>
    </>
  )
}

export default AlbumEdit
